import React from "react"
import { Link } from "gatsby"
import Sidebar from '../../components/sidebar'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import bornetactile from "../../assets/img/bornetactile.jpg"


const borneTactilePage = () => (
  <Layout>
    <SEO title="Borne Tactile" />
    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <div class="grid grid-cols-1 gap-2 md:col-span-3 md:my-5 mx-3"> {/** first element */}
       <h1 class="text-2xl font-bold mb-8 divider">BORNE TACTILE – PLAN DE TRAVAIL TACTILE</h1>
            <img class=" mb-0 float-right" src={bornetactile} alt="borne tactile" />
            <p class="text-justify">
            La TABLE TACTILE – PLAN DE TRAVAIL TACTILE est une borne interactive permettant d’afficher sur l’écran tactile les différentes applications de travail fonctionnelles avec un écran tactile. Le fonctionnement de la TABLE TACTILE – PLAN DE TRAVAIL TACTILE est très intuitif et rend le travail de groupe agréable. Dans une salle de réunion ou de représentation, vous pouvez accueillir vos clients et leurs présenter directement sur l’écran tactile le projet pour lequel ils sont présents. Très luxueux, cette TABLE TACTILE – PLAN DE TRAVAIL TACTILE saura rendre votre espace chaleureux et mettra en avant votre politique novatrice à la pointe de la technologie. Grâce aux applications dédiées, vous pourrez faire passer un document à votre interlocuteur en face de vous ou mettre en plein écran en retournant le document au profit de la lecture de la personne de l’autre côté de la table. La TABLE TACTILE – PLAN DE TRAVAIL TACTILE peut être connectée au réseau ethernet ou directement en WIFI. Nous avons de très bon retour d’expérience avec cette table. Mise en place pour des promoteurs, des structures d’étude, les usagers sont friands de ce type de service. Construite en Solid Surface, la TABLE TACTILE – PLAN DE TRAVAIL TACTILE est robuste tout en gardant un aspect minéral réconfortant et apaisant. Totalement sécurisée la TABLE TACTILE – PLAN DE TRAVAIL TACTILE embarque un ensemble complet de système électrique et électronique pour assurer le bon fonctionnement des PC, écran et autres systèmes intégrés. Nous avons une totale maitrise sur l’ensemble de la fabrication de nos bornes ; Nous apportons les modifications nécessaires aux plans de nos bornes en fonction des matériels à intégrer.
            </p>
       </div>
       <div>
         <Sidebar />
       </div>
    </div>
  </Layout>
  )
 
  export default borneTactilePage